import nxModule from 'nxModule';
import BigNumber from 'bignumber.js';
import {ILocationService, IScope} from "angular";
import {NxIFilterService} from "../../../technical/angular-filters";
import {clone, isNil, pick} from 'lodash/fp';

import './manual-rollover.style.less';

import templateUrl from './manual-rollover.template.html';
import {HttpService} from "shared/utils/httpService";
import {BranchService} from "components/service/branch.service";
import FeeDefinitionCache from "components/service/fee-definition.cache";
import {TermDepositCalculator} from "components/customer/term-deposits/common/term-deposit-calculator.service";
import {Deposit, DepositInterest, Fee, ProductHook} from "components/service/product.types";
import {DepositType, TermDepositService} from "components/administration/term-deposit/common/term-deposit.types";
import {FeeDefinition} from "components/service/fees/fee.types";
import {cycleType, maturityStrategyMapping} from 'constants/deposit';
import {RecursivePartial} from "shared/utils/RecursivePartial";
import Popup from 'shared/common/popup';
import ConfirmationTemplate, {Detail} from 'shared/common/confirmationTemplate';
import moment from 'moment';
import {Branch} from "management/BranchTypes";
import {CommandOutputWrapper} from "command/CommandTypes";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import Notification from "shared/utils/notification";

interface FeeOverride {
  feeDefinitionId: number;
  applyOn: ProductHook;
  applyPredicates: unknown[];
  amount: number;
  paidUpfront: boolean;
}

interface CommandInput {
  productId: number;
  term: number;
  depositInterest: DepositInterest;
  maturityStrategy: string;
  creditingStrategy: string;
  creditingInterval: number;
  creditingCycleType: string;
  beforeRolloverFeeOverride: FeeOverride[];
  certificationFeeOverride: FeeOverride[];
  afterRolloverFeeOverride: FeeOverride[];
  grossInterestOverrideAmount: number;
}

interface FeeWithOverride extends Fee {
  overrideAmount?: number;
}

interface ForecastOutput {
  netInterest: number;
  withholdingTax: number;
  rolloverAmount: number;
  beforeRolloverFees: Fee[];
  certificationFees: Fee[];
  afterRolloverFees: Fee[];
  netRolloverAmount: number; 
}

class DepositManualRollover {
  readonly cycleTypes: typeof cycleType = cycleType;

  customerId!: string;
  depositId!: string;
  deposit!: Deposit;
  branch!: Branch;
  depositType!: DepositType;
  feeDefinitions: Map<number, FeeDefinition> | null = null;
  maturityStrategies: {label: string, value: string}[] = [];
  request: RecursivePartial<CommandInput> = {};
  overrideCheckbox: boolean = false;
  maturityDate: Date | null = null;

  forecast : ForecastOutput | null = null;
  defaultForecast : Omit<ForecastOutput, 'beforeRolloverFees' | 'certificationFees' | 'afterRolloverFees'> & {
    beforeRolloverFees: FeeWithOverride[];
    certificationFees: FeeWithOverride[];
    afterRolloverFees: FeeWithOverride[];
  } | null = null;

  constructor (private $scope: IScope, private $location: ILocationService, private $filter: NxIFilterService,
               private http: HttpService, private command: CommandService, private notification: Notification,
               private confirmationTemplate: ConfirmationTemplate, private customerCache: CustomerCache,
               private termDepositsService: TermDepositService, private branchService: BranchService,
               private termDepositCalculator: TermDepositCalculator, private popup: Popup,
               private feeDefinitionsCache: FeeDefinitionCache) {
  }

  shouldKeepSpecialInterestRate(): boolean {
    return this.deposit.keepSpecialInterestRateEnabled && this.deposit.depositInterest.specialInterestRate;
  }

  async $onInit(): Promise<void> {
    const [deposits, types, branches, feeDefinitions]: [Deposit[], DepositType[], Branch[], FeeDefinition[]] = await Promise.all([
      this.customerCache.termDeposits(this.customerId).toPromise(),
      this.termDepositsService.toPromise(),
      this.branchService.toPromise(),
      this.feeDefinitionsCache.toPromise()
    ]);

    const deposit = deposits.find(d => Number(d.id) === Number(this.depositId));
    if (!deposit) {
      throw new Error(`Deposit ${this.depositId} not found`);
    }

    this.deposit = deposit;
    const depositType = types.find(t => t.id === this.deposit.typeId);
    if (!depositType) {
      throw new Error(`DepositType ${this.deposit.typeId} not found`);
    }

    this.depositType = depositType;
     this.maturityStrategies = this.depositType.maturityStrategies.map(s => {
      return ({
        value: s,
        label: maturityStrategyMapping[s]
      });
    });

    const branch = branches.find(b => b.id === deposit.branchId);
    if (!branch) {
      throw new Error(`Branch ${deposit.branchId} not found`);
    }

    this.feeDefinitions = new Map(feeDefinitions.map(feeDef => [feeDef.id, feeDef]));
    this.request = {
      productId: Number(this.depositId),
      depositInterest: {
        interestRate: 0,
        specialInterestRate: this.shouldKeepSpecialInterestRate()
      },
      term: 1
    };

    if (this.depositType.maturityStrategies.length === 1) {
      this.request.maturityStrategy = this.depositType.maturityStrategies[0];
    }

    if (this.depositType.creditingStrategies.length === 1) {
      this.request.creditingStrategy = this.depositType.creditingStrategies[0];
    }

    if (depositType.prefillManualRollover) {
      this.request.depositInterest = clone(this.deposit.depositInterest);
      this.request.term = this.depositType.useOriginalTermOnRollover ? this.deposit.originalTerm : this.deposit.depositTerm;
      this.request.maturityStrategy = this.deposit.maturityStrategy;
      this.request.creditingStrategy = this.deposit.creditingStrategy;

      if (this.deposit.creditingScheduler.enabled) {
        this.request.creditingInterval = this.deposit.creditingScheduler.interval;
        this.request.creditingCycleType = this.deposit.creditingScheduler.cycleType;
      }
    }

    await this.recalculateDefaultForecast();
  }

  private async recalculateDefaultForecast(): Promise<void> {
    try {
      this.defaultForecast = <ForecastOutput>await this.http.post(`/products/deposits/rollover/simulate`, {
        ...this.request,
        term: this.deposit.depositTerm
      }).toPromise();
    } catch (e) {
      this.popup({
        header: 'Error',
        text: 'Failed to load initial forecast.'
      });
    }
  }

  clearAllForecasts(): void {
    this.forecast = null;
  }

  canComputeForecast(): boolean {
    if(!this.request.term) {
      return false;
    }

    if(!this.defaultForecast) {
      return true;
    }

    if (this.overrideCheckbox) {
      if (isNil(this.request.grossInterestOverrideAmount)) {
        return false;
      }

      for (const fee of [
        ...this.defaultForecast.beforeRolloverFees,
        ...this.defaultForecast.certificationFees,
        ...this.defaultForecast.afterRolloverFees]) {
        if (isNil(fee.overrideAmount)) {
          return false;
        }
      }
    }

    return true;
  }

  async fetchForecast(): Promise<ForecastOutput | null> {
    if(!this.canComputeForecast() || !this.defaultForecast) {
      return null;
    }

    const request = {
      ...this.request,
      beforeRolloverFeeOverride: this.createFeeOverride(this.defaultForecast?.beforeRolloverFees),
      certificationFeeOverride: this.createFeeOverride(this.defaultForecast?.certificationFees),
      afterRolloverFeeOverride: this.createFeeOverride(this.defaultForecast?.afterRolloverFees)
    };

    return <ForecastOutput> await this.http.post(`/products/deposits/rollover/simulate`, request).toPromise();
  }

  async recalculateForecast(): Promise<void> {
    if(!this.request.term || this.request.term < 1) {
      return;
    }

    if(!this.defaultForecast) {
      await this.recalculateDefaultForecast();
    }

    try {
      this.forecast = await this.fetchForecast();
    } catch (e) {
      this.popup({
        header: 'Error',
        text: 'Failed to load override forecast.'
      });
    }
  }

  createFeeOverride(fees: FeeWithOverride[] = []): FeeOverride[] {
    return fees.map(fee => ({
      feeDefinitionId: fee.feeDefinitionId,
      applyOn: fee.applyOn,
      applyPredicates: fee.applyPredicates,
      amount: fee.overrideAmount ?? fee.amount,
      paidUpfront: false
    }));
  }

  async rollover(): Promise<void> {
    if(!this.defaultForecast) {
      throw new Error("Default forecast is mandatory");
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const request: CommandInput = {
      ...pick([
        'productId',
        'term',
        'depositInterest',
        'maturityStrategy',
        'creditingStrategy',
        'creditingInterval',
        'creditingCycleType'
      ], this.request),
      grossInterestOverrideAmount: this.request.grossInterestOverrideAmount!,
      beforeRolloverFeeOverride: this.overrideCheckbox ? this.createFeeOverride(this.defaultForecast?.beforeRolloverFees): [],
      certificationFeeOverride: this.overrideCheckbox ? this.createFeeOverride(this.defaultForecast?.certificationFees): [],
      afterRolloverFeeOverride: this.overrideCheckbox ? this.createFeeOverride(this.defaultForecast?.afterRolloverFees): []
    };

    if(this.overrideCheckbox && !this.forecast) {
      throw new Error("Forecast is mandatory");
    }

    const details: Detail[] = [
      {label: 'Product number', description: this.deposit.productNumber},
      {label: 'Interest rate', description: this.request.depositInterest?.interestRate + '%'},
      {label: 'Deposit term', description: this.request.term + ' days'},
      {label: 'Maturity date', description: this.$filter('prettyDate')(this.maturityDate)},
      {label: 'Rollover amount', description: this.$filter('nxCurrency')(this.forecast?.rolloverAmount ?? this.defaultForecast?.rolloverAmount)},
      {label: 'Net rollover amount', description: this.$filter('nxCurrency')(this.forecast?.netRolloverAmount ?? this.defaultForecast.netRolloverAmount)}
    ];

    if (this.isCreditingStrategyAllowed()) {
      if (!this.request.creditingInterval || !this.request.creditingCycleType) {
        throw new Error("Interest crediting is mandatory");
      }
      details.push({label: 'Interest crediting interval', description: this.request.creditingInterval});
      details.push({label: 'Interest crediting cycle type', description: this.$filter('prettyEnum')(this.request.creditingCycleType)});
    }

    const confirmed = await this.confirmationTemplate({
      question: 'Are you sure you want to proceed with manual rollover?',
      details: details,
      warning: 'This operation cannot be reverted safely.<br>Please make sure that the data is correct.'
    });

    if (confirmed) {
      const response: CommandOutputWrapper<unknown> = await this.command.execute('DepositManualRollover', request).toPromise();
      if (!response.approvalRequired) {
        this.customerCache.termDeposits(this.customerId).refetch();
        this.redirectBack();
      }
    }
  }

  isCreditingStrategyAllowed(): boolean {
    return this.request.creditingStrategy !== 'NOT_ALLOWED';
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/term-deposits/${this.depositId}`);
  }

  resetOverride(): void {
    if (this.overrideCheckbox) {
      return;
    }

    this.request.grossInterestOverrideAmount = undefined;
    this.forecast = null;
  }

  clearForecast(): void {
    this.forecast = null;
  }

  recalculatePreterminationRates(): void {
    if (this.request?.depositInterest?.interestRate && this.request.depositInterest.interestRate > 0) {
      const interestRate = new BigNumber(this.request.depositInterest.interestRate);
      const preterminationFirstHalfMultipler = new BigNumber(0.25).toNumber();
      const preterminationSecondHalfMultipler = new BigNumber(0.50).toNumber();
      this.request.depositInterest.preterminationFirstHalfInterestRate = interestRate.multipliedBy(preterminationFirstHalfMultipler).toNumber();
      this.request.depositInterest.preterminationSecondHalfInterestRate = interestRate.multipliedBy(preterminationSecondHalfMultipler).toNumber();
    }
  }

  onUpdateTerm(): void {
    this.clearAllForecasts();

    if(!this.request.term) {
      return;
    }

    this.maturityDate = moment(this.branch.systemDate).add(this.request.term, 'days').toDate();
  }
}

nxModule.component('depositManualRollover', {
  templateUrl,
  controller: DepositManualRollover,
  bindings: {
    depositId: '<',
    customerId: '<'
  }
});
